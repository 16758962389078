<template>
  <div>
    <div class="flix-form-group">
      <styling :data="data" :callback="function(data) {setStyling(data)}" />
    </div>
    <div class="flix-form-group">
      <contact :data="data" :callback="function(data){setContact(data)}" />
    </div>
    <div class="flix-form-group">
      <lastMinuteBookings :data="data" :callback="function(data){setLastMinute(data)}" />
    </div>
    <div class="flix-row">
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <showAppointments :data="data" :callback="function(data){setShowAppointments(data)}" />
        </div>
      </div>
      <div class="flix-col-md-6">
        <div class="flix-form-group">
          <autoconfirm :data="data" :callback="function(data){setAutoconfirm(data)}" />
        </div>
      </div>
      <div class="flix-col-md-12">
        <div class="flix-form-group">
          <bookingMethod :data="data" :callback="function(data){setBookingMethod(data)}" />
        </div>
      </div>
      <div class="flix-col-md-12" v-if="bookingMethod === 'default'">
        <div class="flix-form-group">
          <verifyTime :data="data" :callback="function(data){setVerifyTime(data)}" />
        </div>
      </div>
    </div>
    <div class="flix-form-group" v-if="variables.user.md5_id">
      <uploadImg :data="data" :callback="function(data){setUpload(data)}"/>
    </div>
    <div class="flix-form-group">
      <saveBtn :onClick="function () { saveData() }"><template v-slot:text>{{ $t('message.save', {name: $t('message.additionalSettings')}) }}</template></saveBtn>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    showAppointments () { return import('@/components/assistent/components/datepicker/showAppointments') },
    autoconfirm () { return import('@/components/assistent/components/datepicker/autoconfirm') },
    contact () { return import('@/components/assistent/components/datepicker/email') },
    lastMinuteBookings () { return import('@/components/assistent/components/datepicker/lastminute') },
    uploadImg () { return import('@/components/assistent/components/eventpicker/upload') },
    styling () { return import('@/components/assistent/components/styling') },
    verifyTime () { return import('@/components/assistent/components/verifyTime') },
    bookingMethod () { return import('@/components/assistent/components/bookingMethod') }
  },
  props: { data: Object, callback: Function },
  data () {
    return {
      showAppointments: this.data.showAppointments,
      lastminute: this.getLastMinute(),
      image: this.getUpload(),
      variables: this.$getUserVariables(),
      autoconfirm: this.getAutoconfirm(),
      styling: this.getStyling(),
      contactName: this.data.contactName,
      contactEmail: this.data.contactEmail,
      verifyTime: this.data.verifyTime,
      bookingMethod: this.getBookingMethod()
    }
  },
  methods: {
    getBookingMethod () {
      if (typeof this.data.bookingMethod === 'undefined') {
        return 'default'
      }
      return this.data.bookingMethod
    },
    getStyling () {
      var styling = {
        accent: '#8dc044'
      }

      if (typeof this.data.styling !== 'undefined') {
        styling = Object.assign(styling, this.data.styling)
      } else {
        this.data.styling = styling
      }
      return styling
    },
    setShowAppointments (data) {
      this.showAppointments = data
    },
    setVerifyTime (data) {
      this.verifyTime = data
    },
    setStyling (data) {
      this.styling = data
    },
    setContact (data) {
      this.contactName = data.contactName
      this.contactEmail = data.contactEmail
    },
    getAutoconfirm () {
      if (typeof this.data.autoconfirm === 'undefined') {
        return false
      }
      return this.data.autoconfirm
    },
    setAutoconfirm (data) {
      this.autoconfirm = data
    },
    setUpload (data) {
      this.image = data
    },
    getUpload () {
      var upload = ''

      if (typeof this.data.image !== 'undefined') {
        upload = this.data.image
      }
      return upload
    },
    getLastMinute () {
      var selected = 0

      if (typeof this.data.exclude.lastminute !== 'undefined') {
        selected = this.data.exclude.lastminute * 1
      }
      return selected
    },
    setLastMinute (data) {
      this.lastminute = data
    },
    setBookingMethod (data) {
      this.bookingMethod = data
    },
    saveData () {
      var data = {}
      data.exclude = Object.assign(this.data.exclude, { lastminute: this.lastminute })
      data.image = this.image
      data.autoconfirm = this.autoconfirm
      data.styling = this.styling
      data.showAppointments = this.showAppointments
      data.contactName = this.contactName
      data.contactEmail = this.contactEmail
      data.verifyTime = this.verifyTime
      data.bookingMethod = this.bookingMethod
      this.callback(data)
    }
  }
}
</script>
